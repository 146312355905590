// import {useEffect, useState} from "react";
import educationData from './data/education.json';
// const educationURL = "http://localhost:3000/resume/education";

function Education() {
    // const [education, setEducation] = useState([]);

    // useEffect( () => {
    //     fetch(educationURL)
    //         .then((res) => res.json())
    //         .then(edu => setEducation(edu.education))
    // }, []);

    return (
        <>
            {
                educationData.education.map((edu, i) => (
                    <div className="py-4" key={edu.school}>
                        <h4 className="m-0" key={edu.degree}>{edu.degree}</h4>

                        <div className="container text-start p-0">
                            <div className="row text">
                                <div className="col-3" key={edu.school}><small><em>{edu.school}</em></small></div>
                                <div className="col-9 text-end" key={edu.years}><small><em>{edu.years}</em></small></div>
                            </div>
                            <div className="row">
                                <p>{edu.blurb}</p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default Education;