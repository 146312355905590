function Hero() {
    const bgImageURL = 'https://shanemerriss.s3.us-west-1.amazonaws.com/images/arches-view.JPEG';
    const selfImageURL = 'https://shanemerriss.s3.us-west-1.amazonaws.com/images/shane-coffeeshop.jpg';


    const imageStyle = {
        backgroundImage: `url(${bgImageURL})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    };

    return (
        <div className="text-center " id="hero" style={imageStyle}>
            <container className="" style={{backgroundColor: "#000"}}>
                <div className="d-flex justify-content-center align-items-center min-vh-100">
                    <div className="container">
                        <div className="row">
                            <div className="d-flex col-sm-6 justify-content-center justify-content-sm-end my-3" >
                                <div className="float-sm-none float-md-end" style={{maxWidth: '200px', height: 'auto'}} >
                                    <img src={selfImageURL} className='img-fluid rounded-circle' alt="Shane coffeeshop"/>
                                </div>
                            </div>

                            <div className="d-flex col-sm-6 justify-content-center justify-content-md-start">
                                <div className="text-light align-self-center">
                                    <h1 className="mb-3">Shane Merriss</h1>
                                    <h4 className="mb-3">Dream. Plan. Implement.</h4>
                                    <a className="btn btn-outline-light btn-lg" href="mailto:Shane@shanemerriss.com" target="_blank" role="button">Contact</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </container>
        </div>
    );
}

export default Hero;