import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';
import Hero from "./components/hero/Hero";
import AboutMe from "./components/about/AboutMe";
import Resume from "./components/resume/Resume";
import Contact from "./components/contact/Contact";

function App() {
  return (
      <div className="">
          <Hero />
          <Resume />
          {/*<AboutMe />*/}
          <Contact />
      </div>
  );
}

export default App;
