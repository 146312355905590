import React from "react";
function ResumeSection({children}) {
   return (
       <>
           {
               React.Children.map(children, child => (
               <div>
                   <div className="container text-start">
                       <div className="row">
                           <div className="col-sm-3 pt-4">
                               <h2><u style={{textDecorationColor: 'darkgray' }}>{child.props.header}</u></h2>
                           </div>
                           <div className="col-sm-9 border-2 border-bottom">
                               {child}
                           </div>
                       </div>
                   </div>
               </div>
               ))
           }
       </>
   )
}

ResumeSection.displayName = 'ResumeSection';
export default ResumeSection;