// import {useEffect, useState} from "react";

import skillsData from './data/skills.json';

// const skillsURL = "http://localhost:3000/resume/skills";
function Skills() {
    // const [skills, setSkills] = useState([]);
    //
    // useEffect( () => {
    //     fetch(skillsURL)
    //         .then((res) => res.json())
    //         .then(skill => setSkills(skill.skills))
    // }, []);

    return (
        <>
            <div className="my-3">
                <ul>
                    {
                        skillsData.skills.map((skill, i) => (
                            <li key={skill.names}>{skill.names}</li>
                        ))
                    }
                </ul>
            </div>
        </>
    )
}

export default Skills;