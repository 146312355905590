// import {useEffect, useState} from "react";
import workData from './data/work.json';
// const experiencesURL = "http://localhost:3000/resume/experiences";

function Work() {
    // const [experiences, setExperiences] = useState([]);

    // useEffect( () => {
    //     fetch(experiencesURL)
    //         .then((res) => res.json())
    //         .then(exp => setExperiences(exp.experiences))
    // }, []);

    return (
        <>
            {
                workData.experiences.map((exp, i) => (
                    <div className="py-4" key={exp.title}>
                        <h4 className="m-0" >{exp.title}</h4>

                        <div className="container text-start p-0">
                            <div className="row text">
                                <div className="col-3" ><small><em>{exp.company}</em></small></div>
                                <div className="col-9 text-end" ><small><em>{exp.date}</em></small></div>
                            </div>
                        </div>

                        <div className="mt-2 ms-2" >
                            <ul>
                                {exp.achievements.map((item, j) => (
                                    <li key={item.achievement}>{item.achievement}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))
            }
        </>
    );
}

Work.displayName = 'Work'
export default Work;