function Contact() {
    const bgImageURL = 'https://shanemerriss.s3.us-west-1.amazonaws.com/images/long-road.JPEG';

    const imageStyle = {
        backgroundImage: `url(${bgImageURL})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundPositionY: '30%'
    };

    return (
        <>
            <div className="text-center" id="contact" style={imageStyle}>
                <container className="" style={{backgroundColor: "#000"}}>
                    <div className="d-flex justify-content-center align-items-center min-vh-100 position-relative">
                        <div className="text-dark position-absolute top-50 bottom-0 translate-middle-y">
                            <h1 className="mb-3">Get In Touch</h1>
                            <p>Shane@shanemerriss.com</p>
                        </div>
                    </div>
                </container>
            </div>
        </>
    )
}

export default Contact;