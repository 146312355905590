import ResumeSection from "./ResumeSection";
import Work from "./Work";
import Skills from "./Skills";
import Education from "./Education";

function Resume() {
    return (
        <div className="text-lg-center bg-light p-5" id="resume">
            <div className="mask" >
                <div className="d-flex justify-content-center align-items-center vh-min-100">
                    <div className="text-dark">

                        <ResumeSection>
                            <Work header="Work"/>
                            <Education header="Education"/>
                            <Skills header="Skills"/>
                        </ResumeSection>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resume;